<!--  -->
<template>
  <div class="main_box">
    <div class="nav_menu">
      <div class="menu_left">
        <el-select v-model="monthInfo.value" placeholder="请选择拦河闸" size="mini">
            <el-option
              v-for="(item,index) in waterList"
              :key="item.deviceAddress"
              :label="index+1+' '+item.siteName"
              :value="item.siteName">
            </el-option>
          </el-select>
        <!-- <el-input
          placeholder="请输入拦河闸名称"
          v-model="monthInfo.value"
        ></el-input> -->
        <el-date-picker
          :editable="false"
          v-model="monthInfo.time"
          type="month"
          placeholder="选择月份"
          class="check_date"
          value-format="yyyy-MM"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <el-button type="primary" @click="handleSearch(0)">查询</el-button>
        <el-button type="info" @click="handleSearch(1)">重置</el-button>
      </div>
      <div class="menu_right">
        <el-button type="success" @click="handleExport">导出</el-button>
      </div>
    </div>
    <div class="columnarChart" id="columnarChart"></div>
    
    <div ref="scroll" @mousewheel="MouseWheel">
      <el-table
        :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF'
        }"
        :data="tableData"
        stripe
        fit
        style="width: 100%"
        v-loading="loading"
        element-loading-text="数据量过大，加载中..."
      >
      <el-table-column type="index" label="序号"> </el-table-column>
        <el-table-column
          prop="stationName"
          label="拦河闸名称"
          :show-overflow-tooltip="true"
          width="90"
        >
        </el-table-column>
        <el-table-column
          v-for="col in cols"
          :prop="col.prop"
          :label="col.label"
          :key="col.prop"
          width="52"
        >
        </el-table-column>
        <el-table-column width='120'  prop="sumRainfall" label="总降雨量(mm)"> </el-table-column>
      </el-table>
      <!-- <el-pagination
        :hide-on-single-page="true"
        background
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
        @current-change="handleCurrent"
        :current-page="pagination.page"
      >
      </el-pagination> -->
    </div>
  </div>
</template>

<script>
import { rainMonth,siteGetSiteAll,FlowList } from "../../../api/index";
import { monthList } from "../index";
let scrollNum = 0;
export default {
  components: {},
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      value: "",
      tableData: [],
       waterList: [],//拦河闸下拉
      pagination: {
        // 分页
        size: 10,
        page: 1,
        total: 0
      },
      time: "",
      monthInfo: {
        time: "",
        value: ""
      },
      year:[],
      cols: monthList,
      loading: false,
      nowTrafficList: [] // 后台返回的今年流量表
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 图表
    init (now, year,name) {
      const columnarChart = document.getElementById('columnarChart')
      const myChart = this.$echarts.init(columnarChart)
      const option = {
        legend: {
          show: true,
          right: '5%',
          top: '0%',
          orient: 'horizontal',
          icon: 'rect',
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            fontSize: 14,
            color: '#D1D1D1'
          }
        },
        xAxis: {
       //   name:"月",
           nameTextStyle:{
            fontSize:10,  
        },
          type: 'category',
          data: year,
          axisLabel: {
            formatter:'{value}日',
            show: true,
            textStyle: {
              fontWeight: 'bold'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          name:"",//单位
           nameTextStyle:{
            fontSize:10,  
        },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontWeight: 'bold'
            }
          }
        },
        grid: {
          top: 30,
          x: 58,
          x2: 30,
          y2: 35
        },
        tooltip: {
          trigger: 'axis',
          formatter: name+' <br> {b0}日 ：{c0} mm'
        },
        series: [
          {
         //   name: '今年',
            data: now,
            type: 'bar',
            stack: 'year',
            emphasis: {
              focus: 'series'
            },
            barWidth: '57%',
            label: {
              show: false
            },
            itemStyle: {
              normal: {
                label: {
                  show: false,
                  textStyle: {
                    fontSize: 12,
                    fontWeight: 'bold'
                  }
                },
                color: '#8270fc'
              }
            }
          },
        ]
      }
      myChart.setOption(option)
      // 图表自适应
      window.addEventListener('resize', function () {
        myChart.resize()
      })
      
    },
    
    // getData (val) {
    //   FlowList(val).then(res => {
    //     console.log(res)
    //     // 今年流量表
    //     this.nowTrafficList = res.data.nowTraffic
    //     this.init(
    //       this.nowTrafficList.map(item => item.sumTraffic),
    //       res.data.nowTraffic.map(item => item.month))
    //   })
    // },
    MouseWheel(e) {
      scrollNum += e.deltaY
      this.$refs.scroll.children[0].children[2].scrollTo(scrollNum, 0);
    },
    nums(s) {
      return s < 10 ? "0" + s : s;
    },
    getList() {
      this.loading = true;
      let { page, size } = this.pagination;
      let date = new Date();
      this.time =
        date.getFullYear() +
        "-" +
        this.nums(date.getMonth() - 0 + 1) +
        "-" +
        date.getDate();
      this.monthInfo.time = this.time;
      rainMonth({
        page,
        size,
        time: this.time
      }).then(res => {
        this.loading = false;
        this.pagination.total = res.data.total;
        this.tableData = res.data.list.map(item => {
          let num = item.dayRainVos.length;
          let obj = {};
          obj.stationName = item.rainName;
          item["dayRainVos"].forEach(items => {
            obj["rainDay" + items.rainDay] = items["rainfall"];
            obj["sumRainfall"] = item["sumRainfall"];
          });
          return obj;
        });
        console.log(this.tableData);
            const arr = res.data.list[0].dayRainVos
            const name =res.data.list[0].dayRainVos[0].rainName
            const nowList = arr.map(item => item.rainfall)
            const nowName = arr.map(item => item.rainDay)
            const max = Math.max(...nowList)
            const min = Math.min(...nowList)
            this.year=[]
                  for(let i=0;i<=this.cols.length;i++){
                    this.year.push(i+1)
                  }
            this.init(
            nowList,this.year,name
              )
      });
    },
    // 分页
    handleCurrent(pages) {
      this.pagination.page = pages;
      this.loading = true;
      let { page, size } = this.pagination;
      let date = new Date();
      this.monthInfo.time = this.time;
      rainMonth({
        page,
        size,
        time: this.time
      }).then(res => {
        this.loading = false;
        this.pagination.total = res.data.total;
        this.tableData = res.data.list.map(item => {
          let num = item.dayRainVos.length;
          let obj = {};
          obj.stationName = item.rainName;
          item["dayRainVos"].forEach(items => {
            obj["rainDay" + items.rainDay] = items["rainfall"];
            obj["sumRainfall"] = item["sumRainfall"];
          });
          return obj;
        });
            const arr = res.data.list[0].dayRainVos
            const name =res.data.list[0].dayRainVos[0].rainName
            const nowList = arr.map(item => item.rainfall)
            const nowName = arr.map(item => item.rainDay)
            const max = Math.max(...nowList)
            const min = Math.min(...nowList)
           this.year=[]
                  for(let i=0;i<=this.cols.length;i++){
                    this.year.push(i+1)
                  }
            this.init(
            nowList,this.year,name
              )
      });
    },
    // 搜索
    handleSearch(type) {
      this.loading = true;
      if (type == 1) {
        this.$set(this.monthInfo, "time", "");
        this.$set(this.monthInfo, "value", this.waterList[0].siteName);
        this.pagination.page = 1;
        this.getList();
      } else {
        let { value, time } = this.monthInfo;
        this.time = time;
        rainMonth({
          page: 1,
          size: 10,
          rainName: value,
          time
        }).then(res => {
          this.loading = false;
          if (res.code == 200) {
            if (res.data) {
              this.tableData = res.data.list.map(item => {
                let obj = {};
                obj.stationName = item.rainName;
                item["dayRainVos"].forEach(items => {
                  obj["rainDay" + items.rainDay] = items["rainfall"];
                  obj["sumRainfall"] = item["sumRainfall"];
                });
                return obj;
              });
               const arr = res.data.list.find((name)=>{
                          return  name.rainName==value
                          })
              const name =arr.rainName
              const nowList = arr.dayRainVos.map(item => item.rainfall)
                const nowName = arr.dayRainVos.map(item => item.rainDay)
                const max = Math.max(...nowList)
                const min = Math.min(...nowList)
               this.year=[]
                  for(let i=0;i<=this.cols.length;i++){
                    this.year.push(i+1)
                  }
                this.init(
                nowList,this.year,name
                  )
            } else {
              this.tableData = [];
            }
          }
        });
      }
    },
    handleExport() {
      let { value, time } = this.monthInfo;
      if (time !== "") {
        fetch(
          this.$axios.defaults.baseURL +
            `rainfallRegime/month/export?time=${time}&rainName=${value}`,
          {
            headers: {
              token: localStorage.getItem("token")
            }
          }
        )
          .then(res => {
            return res.blob();
          })
          .catch(err => {
            console.log(err);
          })
          .then(e => {
            const a = document.createElement("a");
            a.href = URL.createObjectURL(e);
            a.download = "雨情月报表.xlsx";
            a.click();
          });
      } else {
        fetch(
          this.$axios.defaults.baseURL +
            `rainfallRegime/month/export?rainName=${value}`,
          {
            headers: {
              token: localStorage.getItem("token")
            }
          }
        )
          .then(res => {
            return res.blob();
          })
          .catch(err => {
            console.log(err);
          })
          .then(e => {
            const a = document.createElement("a");
            a.href = URL.createObjectURL(e);
            a.download = "雨情月报表.xlsx";
            a.click();
          });
      }
    }
  },
  created() {
    siteGetSiteAll().then(res=>{
          this.waterList=res.data
         this.monthInfo.value=this.waterList[0].siteName
      })
    this.getList();
   // this.getData()
  },
  mounted() {
  }
};
</script>
<style lang='scss' scoped>
::v-deep .el-table {
  height: calc(540 / 1080 * 100vh) !important;
  margin-bottom: 15px !important;
  background: transparent;
}
.columnarChart{
  width: 100%;
  height: 230px;
  margin: 0px auto;
}

</style>
